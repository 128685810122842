<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="国家" prop="country" >
        <a-input v-model="form.country" placeholder="请输入国家" />
      </a-form-model-item>
      <a-form-model-item label="国家英文" prop="countryEn" >
        <a-input v-model="form.countryEn" placeholder="请输入国家英文" />
      </a-form-model-item>
      <a-form-model-item label='是否启用' prop='isOpen'>
        <switch-pack   v-model="form.isOpen"/>
      </a-form-model-item>

      <a-form-model-item label='排序' prop='sort'>
        <a-input-number style='width: 150px' v-model='form.sort' placeholder='请输入排序' :min='0' :maxLength='10' :max='9999' />
      </a-form-model-item>
<!--      <a-form-model-item label="是否启用" prop="isOpen" >-->
<!--        <a-input v-model="form.isOpen" placeholder="请输入是否启用" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCountry, addCountry, updateCountry } from '@/api/video/country'
import SwitchPack from '@/components/SwitchPack.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    SwitchPack
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        country: null,

        countryEn: null,

        createTime: null,

        remark: null,

        isOpen: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        country: [
          { required: true, message: '国家不能为空', trigger: 'blur' }
        ],
        countryEn: [
          { required: true, message: '国家英文不能为空', trigger: 'blur' }
        ],
        isOpen: [
          { required: true, message: '是否启用不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        country: null,
        countryEn: null,
        createTime: null,
        remark: null,
        isOpen: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCountry({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCountry(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCountry(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
